/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { useOvermind } from '../overmind';

const AuthRoute = ({ children, authType, ...rest }) => {
  const location = useLocation();
  const {
    actions,
    state: { authToken, refreshToken }
  } = useOvermind();

  const token = Cookies.get('auth_token');
  const refresh_token = Cookies.get('refresh_token');

  const canRender =
    (authType === 'authorized' && token) || authType === 'unauthorized';

  const AuthSelene = async () => {
    await actions.updateToken({
      token: token,
      refreshToken: refresh_token
    });
  };

  const history = useHistory();
  useEffect(() => {
    if (!canRender) {
      history.push('/auth');
      return;
    }
    AuthSelene(); //TODO: VALIDA WARNINGS
  }, []);

  if (!authToken) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => (canRender ? children : <></>)}
    />
  );
};

export default AuthRoute;
